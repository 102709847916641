import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Adsense } from '@components/Adsense/Adsense'
import { Box, Label, Input, Textarea, Button, Message, Spinner, Text} from 'theme-ui'
import { Link } from 'gatsby';


const PagePrestamosGalicia = props => {
  

  return (
    <Layout {...props}>
      <Seo title='Prestamos-Banco-Galicia' />
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2292291997787849"
     crossOrigin="anonymous"></script>
      <Divider />
      <Adsense />

      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Préstamos Personales de Banco Galicia 💸'
          subheader='Descubre los montos, tasas, requisitos y pasos necesarios para obtener un préstamo en el Banco Galicia y alcanzar tus metas financieras.'
        />
      </Stack>
    
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="/prestamos-personales-santander">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Préstamo de $650.000 💰 </Button>
        </Link>
        <Link to="/efectivo-si">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Préstamo de $300.000 💸 </Button>
        </Link>
        <Link to="/credito-hipotecario">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Sacá tu Crédito Hipotecario 💸🏡 </Button>
        </Link>
        <Link to="/prestamos-bbva">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Préstamo de $80.000 💳 </Button>
        </Link>
      </div>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          Obtener un préstamo en el Banco Galicia puede ser una excelente opción para acceder a financiamiento confiable. 
          Ya sea para cubrir gastos personales, emprender un negocio o concretar proyectos importantes, el Banco Galicia ofrece diversas alternativas. 
          En esta nota, te mostraremos hasta qué monto puedes solicitar, las tasas de interés aplicables, los requisitos necesarios y los pasos a seguir para obtener un préstamo.
          <br><br>
          ¿Hasta qué monto puedes solicitar un préstamo en el Banco Galicia?
          <br><br>
          El monto máximo que puedes solicitar como préstamo en el Banco Galicia varía según el tipo de crédito. 
          Para préstamos personales, el monto máximo es de $3.000.000. Si necesitas un préstamo hipotecario, 
          el banco te brinda la posibilidad de acceder a montos más elevados, 
          según la evaluación de tu capacidad de pago y el valor de la propiedad.
          <br><br>
          Tasas de interés y requisitos
          <br><br>
          Las tasas de interés aplicables a los préstamos del Banco Galicia están sujetas a diferentes factores, como la línea de crédito, 
          el plazo y la relación del cliente con el banco. Es importante tener en cuenta que las tasas pueden variar en función de las condiciones del mercado y las políticas del banco en cada momento. 
          Para obtener información actualizada sobre las tasas de interés vigentes, es recomendable consultar directamente con el Banco Galicia.

          '
        />
      </Stack>
      <Adsense />

      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          
          En cuanto a los requisitos para solicitar un préstamo en el Banco Galicia, los principales suelen ser:
          <br>
          - Ser mayor de edad.<br>
          - Presentar Documento Nacional de Identidad (DNI) argentino vigente.<br>
          - Acreditar ingresos suficientes y estables.<br>
          - Demostrar antigüedad laboral o actividad económica estable.<br>
          - Presentar comprobantes de ingresos, como recibos de sueldo o declaraciones juradas de ingresos.<br>
          - Cumplir con los requisitos específicos según el tipo de préstamo solicitado.<br>
          '
        />
      </Stack>
      <Adsense />

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="https://anses.blog/sorteo-procrear-2023-diciembre-ganadores-y-lo-que-viene-para-el-2024/" style={{ textDecoration:'none', }}>
        <Text style={{margin: '5px', width: '100%', height: '100%', fontWeight:'600'}}><span style={{fontWeight:'700'}}>
          También puede interesarte: </span>Plan Procrear II: Créditos de $3.000.000 para la casa propia y oportunidad de sorteo en julio</Text>
        </Link>
      </div>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          Pasos para solicitar un préstamo en el Banco Galicia: <br>

          - Investigación: Antes de solicitar un préstamo, investiga las diferentes líneas de crédito ofrecidas por el Banco Galicia. Compara las tasas de interés, montos máximos, plazos y condiciones para encontrar la opción que se ajuste mejor a tus necesidades.<br>
          - Reunir la documentación: Prepara los documentos necesarios, como tu DNI, comprobantes de ingresos y cualquier otro requisito específico solicitado por el banco.<br>
          - Contacto con el banco: Puedes iniciar el proceso de solicitud comunicándote con el Banco Galicia a través de su página web, por teléfono o visitando una sucursal física. Un asesor te guiará en el proceso y responderá tus preguntas.<br>
          - Evaluación crediticia: El banco realizará una evaluación de tu capacidad crediticia y analizará tu historial financiero para determinar la viabilidad de otorgarte el préstamo solicitado. Esto incluye verificar tus ingresos, nivel de endeudamiento y antecedentes crediticios.<br>
          - Aprobación y firma: Si tu solicitudes aprobada, recibirás la notificación correspondiente. En ese momento, deberás acudir a una sucursal del Banco Galicia para firmar los documentos y completar el proceso.<br><br>

          Recuerda que este es solo un resumen general de los pasos a seguir y los requisitos para solicitar un préstamo en el Banco Galicia. 
          Te recomendamos contactar directamente con el banco para obtener información detallada y actualizada sobre los productos financieros disponibles y los requisitos específicos para cada línea de crédito.
          En resumen, el Banco Galicia brinda oportunidades de financiamiento atractivas y flexibles. 
          Si cumples con los requisitos y sigues los pasos adecuados, podrás acceder al préstamo necesario para alcanzar tus metas financieras y concretar tus proyectos.
          
          '
        />
      </Stack>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="https://www.anses.blog/como-solicitar-el-nuevo-credito-de-1-millon-de-la-anses-para-trabajadores/" style={{ textDecoration:'none', }}>
          <Text style={{margin: '5px', width: '100%', height: '100%', fontWeight:'600'}}><span style={{fontWeight:'700'}}>
            También puede interesarte: </span>Nuevo Crédito de $1 Millón de la Anses para Trabajadores💫</Text>
        </Link>
      </div>
      <Adsense />

    </Layout>
  )
}

export default PagePrestamosGalicia
